<template>
    <div style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>课后反馈</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="messages">
            <div class="clearfix messages-search">
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>时间筛选</span>
                            </template>
                            <a-range-picker
                                style="width: 240px"
                                :ranges="rangesData"
                                :value='rangesValue'
                                @change="(date, dateString)=>onChange(date, dateString,2)" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-popover placement="bottom" trigger="click" v-model="visible">
                                <template slot="content">
                                <div class="more-search">
                                    <a-form layout='inline'>
                                    <a-form-item class="block-line" label="选择学员" >
                                        <a-select allowClear showSearch @search="onSearch" v-model="searchParams.search.student_id" placeholder="请选择学员" :filterOption="filterOption">
                                            <a-select-option v-for="(item, index) of students" :key="index" :value="item.student_id">{{ item.student_name }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="发布账户" >
                                        <a-select allowClear v-model="searchParams.search.created_by" placeholder="请选择发布账户" :filterOption="filterOption">
                                            <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    </a-form>
                                    <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button type="primary" @click="searchList">搜索</a-button>
                                    </div>
                                </div>
                                </template>
                                <a-button>更多<a-icon type="down" /></a-button>
                            </a-popover>
                            </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="searchList">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
                class="messages-list"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
                <div class="messages-send">
                    <div>
                        <a-mentions v-model="mentionsValue" :loading="stuLoading" @search="onSearch" @select="onSelect" rows="4" placeholder="请先使用@xxx来选择学员">
                            <a-mentions-option v-for="item in students" :key="item.student_id" :value="`${item.student_name}+=${item.student_id}+=`">
                                <a-avatar :src="item.student_avatar" icon="user" />
                                {{item.student_name}} ({{item.student_cellphone}})
                            </a-mentions-option>
                        </a-mentions>
                    </div>
                    <div class="messages-send-icon">
                        <div class="messages-send-iconL">
                            <a-upload
                                name="file" 
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeImageUpload"
                                :showUploadList="false"
                                listType="picture"
                                @change="changeFile">
                                <a-button>
                                    <a-icon type="picture" /> 图片
                                </a-button>
                            </a-upload> 
                            <a-upload 
                                name="file"
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeUpload"
                                :showUploadList="false"
                                @change="changeFile2">
                                <a-button>
                                    <a-icon type="sound" /> 音频
                                </a-button>
                            </a-upload>
                            <a-upload 
                                name="file"
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeUpload"
                                :showUploadList="false"
                                @change="changeFile3">
                                <a-button>
                                    <a-icon type="video-camera" /> 视频
                                </a-button>
                            </a-upload>
                            <div>
                                <a-popover v-model="zlVisible" title="" trigger="click" placement="bottom">
                                    <div slot="content">
                                        <a-select allowClear placeholder="请选择" v-model="messageKnowledge" style="width:150px">
                                            <a-select-option v-for="(item, index) of zlList" :key="index" :value="item.id">{{ item.file_name }}</a-select-option>
                                        </a-select>
                                    </div>
                                    <a-button>
                                        <a-icon type="file" /> 资料
                                    </a-button>
                                </a-popover>
                            </div>
                        </div>
                        <div>
                            <a-button @click="toSend" type="primary">发布</a-button>
                        </div>
                    </div>
                    <div>
                        <div v-for="(item, idx) of fileList2" :key="idx" class="messages-send-audio">
                            <audio :src="`http://v01.wedots.cn/${item.response.key}`" controls />
                            <a-icon @click="delUpload(idx,'fileList2')" class="messages-send-audio-icon" type="delete" />
                        </div>
                        <div v-if="audioLoading" class="messages-send-audio-loading"><vue-element-loading :active="audioLoading" color="#00cca2" spinner="spinner"/></div>
                    </div>
                    <div class="messages-send-img">
                        <div class="messages-send-imgBox" v-for="(item,index) in fileList" :key="index">
                            <img :preview="index" :src="`http://i01.wedots.cn/${item.response.key}`">
                            <div @click.stop="delUpload(index,'fileList')" class="messages-send-close">
                                <a-icon type="close" />
                            </div>
                        </div>
                        <div @click="showVideoModal(`http://v01.wedots.cn/${video.response.key}`)" v-for="(video, idx) of fileList3" :key="idx + 100"  class="messages-send-video">
                            <video v-if="video.response.key" style="background:#000;border-radius:2px" :src="`http://v01.wedots.cn/${video.response.key}`"  width="60" height="60">
                                Your browser does not support the video tag.
                            </video>
                            <div class="messages-send-video-icon">
                                <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                            </div>
                            <div @click.stop="delUpload(index,'fileList3')" class="messages-send-close">
                                <a-icon type="close" />
                            </div>
                        </div>
                        <div v-if="upLoadLoading" class="messages-send-loading">
                            <vue-element-loading :active="upLoadLoading" color="#00cca2" spinner="spinner"/>
                        </div>
                    </div>
                </div>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <a-card class="messages-card">
                            <div class="messages-card-nav">
                                <a-avatar :src="item.creater.teacher_avartar" :size="35" icon="user" />
                                <div class="messages-card-name">
                                    <div>{{item.creater.teacher_name}}</div>
                                    <!-- <a-icon :type="item.message_to.to=='student'?'user':'team'" />  -->
                                    <div style="font-size: 12px;">{{item.message_created_at}}  ·  <span @click="handleStudent(item)" style="color:#00cca2;cursor: pointer;"> {{item.message_to.to_name}}</span></div>
                                </div>
                            </div>
                            <div class="messages-card-mar messages-card-text">
                                {{item.message_content}}
                            </div>
                            <div class="messages-card-mar messages-card-img">
                                <img :preview="index" v-for="(it,ind) in item.message_images" :key="ind" :src="it">
                                <div @click="showVideoModal(video)" v-for="(video, idx) of item.message_video" :key="idx + 100"  class="messages-card-video">
                                    <video style="background:#000;border-radius:2px" :src="video"  width="60" height="60">
                                        Your browser does not support the video tag.
                                    </video>
                                    <div class="messages-card-video-icon">
                                        <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.message_audio.length > 0" class="messages-card-mar">
                                <audio v-for="(img, idx) of item.message_audio" :key="idx" :src="img.audio_path" controls />
                            </div>
                            <div class="messages-card-mar messages-card-rate" v-if="item.message_template_content.length > 0">
                                <img class="messages-card-rateImg" :src="`https://i01.wedots.cn/wxapp/star/${report(item.message_template_content)}.png`">
                                <div>
                                    <div class="messages-card-rateBox" v-for="(it,ind) in item.message_template_content" :key="ind">
                                        <div class="messages-card-rateName">{{it.name}}</div>
                                        <a-rate :default-value="it.star" disabled allow-half />
                                    </div>
                                </div>
                            </div>
                            <template slot="actions" class="ant-card-actions">
                                <div><a-icon key="eye" type="eye" /><span style="margin-left:5px">{{item.view_count}}</span></div>
                                <div @click="item.comment_show = !item.comment_show;comment_entity = item.comment_entity;commnet_index = index">
                                    <a-icon key="message" type="message" />
                                    <span style="margin-left:5px">{{item.comment_count}}</span>
                                </div>
                                <div><a-icon key="like" type="like" /><span style="margin-left:5px">{{item.like_count}}</span></div>
                                <!-- <a-icon key="menu" type="menu" /> -->
                            </template>
                        </a-card>
                        <div v-if="item.comment_show" class="messages-comments">
                            <div class="messages-comments-reply">
                                <a-textarea @keyup.ctrl.enter="addComment" placeholder="请输入评论..." v-model="allTextArea" :auto-size="{ minRows: 1, maxRows: 3 }"/>
                                <div class="messages-comments-btn">
                                    <a-button @click="addComment" size="small" type="primary">评论</a-button>
                                </div>
                            </div>
                            <a-comment v-for="(it,j) in item.comments" :key="it.commnet_id" :class="j == item.comments.length - 1?'':'messages-comments-border'">
                                <a slot="author">{{it.commnet_name}}</a>
                                <span @click="toReply(item,it,index)" slot="actions">回复</span>
                                <a-avatar
                                slot="avatar"
                                :src="it.commnet_avatar"
                                />
                                <p slot="content">
                                    {{it.commnet_content}}
                                </p>
                                <span slot="datetime">{{it.comment_at}}</span>
                                <a-comment v-for="(i,k) in it.commnet_children" :key="i.commnet_id">
                                    <a slot="author">{{i.commnet_name}}</a>
                                    <span @click="toReply(item,i,index)" slot="actions">回复</span>
                                    <a-avatar
                                    slot="avatar"
                                    :src="i.commnet_avatar"
                                    />
                                    <p slot="content">
                                        {{i.commnet_content}}
                                    </p>
                                    <span slot="datetime">{{i.comment_at}}</span>
                                </a-comment>
                            </a-comment>
                        </div>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
        </div>
        <videoModal v-if="isVideoModal" :video='video'/>
    </div>
</template>

<script>
    import moment from 'moment'
    import url from '@/utils/URL'
    import { delay } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    import videoModal from '../review/videoModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import ranges from "@/common/mixins/ranges"
    import contractStatisVue from '../../analysis/kcsjtj/contractStatis.vue'
    export default {
        name:'messages',
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                busy: true,
                visible: false,
                zlVisible: false,
                loading: false,
                stuLoading: false,
                upLoadLoading: false,
                audioLoading: false,
                list:[],
                teachers:[],
                video:'',
                isVideoModal:false,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                    },
                    "sort": ''
                },
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                fileList: [],
                fileList2: [],
                fileList3: [],
                zlList:[],
                messageKnowledge:undefined,
                student_id:'',
                student_name:'',
                mentionsValue:'',
                students:[],
                allTextArea:'',
                comment_entity:'',
                commnet_index:'',
                commnet_name:'',
                commnet_id:'',
            }
        },
        components: {
            videoModal,
        },
        directives: { infiniteScroll },
        mixins:[ranges],
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.rangesValue = []
            this.getList()
            this.getTeacher()
            this.getZlList()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                this.searchParams.search.start_date = this.paramsData.start_date
                this.searchParams.search.end_date = this.paramsData.end_date
                let res = await this.$store.dispatch('scheduleMessagesAction',this.searchParams)
                if(this.searchParams.page == 1){
                    this.busy = false
                }
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }
                if(res._meta.totalCount == this.list.length){
                    this.busy = true
                }
                this.pageParams = res._meta
                this.loading = false
            },
            async getTeacher() {
                let res = await this.$store.dispatch('searchTeacherAction', {})
                this.teachers = res.data
            },
            async getZlList() {
                let res = await this.$store.dispatch('zlShareListAction', { })
                this.zlList = res.items
            },
            async handleSearch(obj) {
                let res = await this.$store.dispatch('searchStudentsAction', { params:obj })
                this.students = res.data
                this.stuLoading = false
                // this.$forceUpdate()
            },
            report(e){
                var num=0
                e.forEach(function (item, index){
                    num += Number(item.star*2)
                })
                var data = parseInt(num/e.length)/2
                if (data.toString().indexOf('.')==-1){
                    data=data.toString()+'.0'
                }
                return data
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            async beforeImageUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            async beforeUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('massTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeFile({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList.push(file)
                    this.upLoadLoading = false
                }
                console.log(file,fileList)
            },
            changeFile2({ file, fileList }) {
                this.audioLoading = true
                if(file.status == "done"){
                    this.fileList2.push(file)
                    this.audioLoading = false
                }
                console.log(fileList)
            },
            changeFile3({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList3.push(file)
                    this.upLoadLoading = false
                }
                console.log(fileList)
            },
            delUpload(index,name){
                this[name].splice(index,1)
            },
            async toSend(){
                let params = {}
                if(!this.mentionsValue){
                    this.$message.warning('请输入动态内容!!')
                    return false
                }
                if(this.mentionsValue.indexOf(this.student_name) == -1){
                    this.$message.warning('请选择学员!!')
                    return false
                }
                params.message_content = this.mentionsValue
                if (this.fileList.length) {
                    let arr = []
                    for (let file of this.fileList) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    params.message_images = arr
                }
                if (this.fileList2.length) {
                    let arr = []
                    for (let file of this.fileList2) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    params.message_audios = arr
                }
                if (this.fileList3.length) {
                    let arr = []
                    for (let file of this.fileList3) {
                    if (file.response.key) {
                        arr.push(file.response.key)
                    }
                    }
                    params.message_videos = arr
                }
                if(this.messageKnowledge){
                    params.messageKnowledge = this.messageKnowledge
                }
                params.student_id = this.student_id
                await this.$store.dispatch('daMessagesPublishAction', { data: params })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.searchList()
                    this.student_id = ''
                    this.student_name = ''
                    this.mentionsValue = ''
                    this.fileList = []
                    this.fileList2 = []
                    this.fileList3 = []
                    this.messageKnowledge = undefined
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            toReply(item,it,index){
                this.comment_entity = item.comment_entity
                this.commnet_id = it.commnet_id
                this.commnet_index = index
                this.commnet_name = it.commnet_name
                this.allTextArea = `回复:${it.commnet_name} `
            },
            async addComment(){
                if(!this.allTextArea){
                    this.$message.warn('请输入评论!!')
                    return false
                }
                let answer = ''
                if(this.allTextArea.indexOf(`回复:${this.commnet_name}`) != -1){
                    if(this.allTextArea.indexOf(`回复:${this.commnet_name} `) != -1){
                        answer = this.allTextArea.split(`回复:${this.commnet_name} `)[1]
                    }else{
                        answer = this.allTextArea.split(`回复:${this.commnet_name}`)[1]
                    }
                }else{
                    answer = this.allTextArea
                }
                let obj = {
                    comment_entity:this.comment_entity,
                    parentId:this.commnet_id,
                    comment_content:answer
                }
                await this.$store.dispatch('scheduleAddCommentAction', obj)
                .then(res=>{
                    this.allTextArea = ''
                    this.commnet_id = ''
                    this.list[this.commnet_index].comment_count++
                    this.list[this.commnet_index].comments = res.data.message_comments
                    this.$message.success('操作成功~')
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            onSearch(search){
                this.stuLoading = !!search
                this.students = []
                let obj = {
                    q: search,
                }
                delay(() => {
                    this.handleSearch(obj)
                }, 500)
            },
            onSelect(option) {
                console.log('select', option)
                let strArr = this.mentionsValue.split('+=')
                this.mentionsValue= strArr[0] + strArr[2]
                this.student_id = strArr[1]
                this.student_name = strArr[0]
            },
            handleStudent(item){
                if(item.message_to.to === 'student'){
                    this.students = [{
                        student_name:item.message_to.to_name,
                        student_id:item.message_to.to_id,
                        student_avatar:item.message_to.to_avatar,
                        student_cellphone:''
                    }]
                    this.busy = true
                    this.searchParams.search.student_id = item.message_to.to_id
                    this.searchParams.page = 1
                    this.list = []
                    this.getList()
                }
            },
            searchList(){
                this.busy = true
                this.searchParams.page = 1
                this.list = []
                this.getList()
            },
            closeSearch() {
                this.searchVisible = false
            },
            reset() {
                this.searchParams.search = {is_sub:this.searchParams.search.is_sub}
            },
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        &-list{
            height: calc(100vh - 205px);
            overflow-y: auto;
        }
        &-search{
            width: 700px;
            margin: 0 auto;
        }
        &-send{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-audio{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                &-icon{
                    margin-left: 10px;
                    font-size: 24px;
                    cursor: pointer;
                }
                &-loading{
                    width: 300px;
                    height: 54px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        &-card{
            width: 700px;
            margin: 0 auto;
            &-nav{
                display: flex;
            }
            &-name{
                margin-left: 20px;
            }
            &-mar{
                margin-left: 55px;
                margin-top: 10px;
            }
            &-text{
                white-space: normal;
                word-break: break-all;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-rate{
                display: flex;
                align-items: center;
            }
            &-rateImg{
                width: 100px;
                margin-right: 50px;
            }
            &-rateBox{
                display: flex;
                align-items: center;
            }
            &-rateName{
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis
            }
        }
        &-comments{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 12px 24px;
            textarea{
                resize: none;
                border:1px solid #fff;
            }
            &-reply{
                border: 1px solid #e8e8e8;
                padding: 5px;
                border-radius: 2px;
                margin-bottom: 5px;
            }
            &-btn{
                text-align: right;
                margin-top: 5px;
            }
        }
        .ant-list-item{
            border-bottom: none;
            display: block;
        }
    }
</style>
<style lang="scss">
    .messages{
        .ant-comment-inner{
            padding-top: 5px;
            padding-bottom: 0px;
            p{
                margin-bottom:0px;
                padding-bottom: 5px;
            }
        }
        .messages-comments-border{
            p{
                border-bottom: 1px solid #e8e8e8;
            }
        }
        .ant-comment-actions{
            margin-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
</style>